import { post, get, downFile } from 'common/request'

export default {
    //分页查询
list: args => get('/scEvent/list', args),
//获取网格树
getTreeList: args => get('/scGridManagement/getTreeList', args),
  // 获取字典数据
  dictData: args => get('/dict-data/getByDicKind', args),
 //人员类型 
 residentType: args => get('/scEventStatistics/residentType', args),
//年龄分布
residentAge: args => get('/scEventStatistics/residentAge', args),
//评价情况
residentReview: args => get('/scEventStatistics/residentReview', args),
// 性别占比
 residentGender: args => get('/scEventStatistics/residentGender', args),

//人员数量
residentNum: args => get('/scEventStatistics/residentNum', args),
// 所属网格
residentGridNum: args => get('/scEventStatistics/residentGridNum', args),
}
