<!--活动管理  -->
<template>
  <div>
    <ax-table
      ref="roleTable"
      :show-search="true"
      :searchForm="searchForm"
      :searchActions="searchActions"
      :columns="columns"
      :dataSourceApi="api.list"
      :dataSourceParams="dataSourceParams"
      :clickable="false"
      :showToolBar="false"
      :rowSelection="{
        onChange: onSelectChange,
        type: radio,
      }"
      :dynamicSlotNames="['gridId', 'gridId']"
      @reset="reset"
      @searchlist="searchlist"
       :scroll="{ y: '55vh', x: '80vw' }"
    >
      <!--  :showToolBar="true"  表格列设置 -->
      <div slot="gridId" style="display: flex">
        <a-cascader
          :options="options"
          placeholder="请选择所属网格"
          change-on-select
          @change="onChange"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :z-index="666"
          :model="cuurentAreaCode"
          v-model="cuurentAreaCode1"
        />
      </div>
    </ax-table>
    <div style="display: flex; justify-content: flex-end; margin-top: 20px">
      <a-button type="primary" @click="onSubmit"> 保存 </a-button>
      <a-button style="margin-left: 20px" @click="close"> 取消 </a-button>
    </div>
  </div>
</template>
    <script>
import api from "./api";
import axTable from "@/components/ax-table";

const type = [
  { label: "志愿活动", value: "0" },
  { label: "公益活动", value: "1" },
  { label: "教育讲座", value: "2" },
  { label: "文艺表演", value: "3" },
  { label: "体育竞赛", value: "4" },
  { label: "节日庆典", value: "5" },
  { label: "防灾演练", value: "6" },
  { label: "环保行动", value: "7" },
  { label: "其他", value: "8" },
];

const state = [
  { label: "待报名", value: "0" },
  { label: "报名中", value: "1" },
  { label: "待开始", value: "2" },
  { label: "进行中", value: "3" },
  { label: "已结束", value: "4" },
];

const searchForm = [
  {
    label: "关键词",
    type: "inputSearch",
    model: "keyword",
    options: { placeholder: "活动名称/举办地点/举办单位" },
    formItem: {},
    col: { span: 12 },
  },
  {
    label: "活动类型",
    type: "select",
    model: "eventType",
    options: { options: type, placeholder: "活动类型" },
    formItem: {},
    col: { span: 12 },
  },
  {
    label: "所属网格",
    type: "select",
    model: "gridId",
    options: { placeholder: "所属网格" },
    formItem: {},
    col: { span: 12 },
  },
  {
    label: "活动状态",
    type: "select",
    model: "eventState",
    options: { options: state, placeholder: "活动状态" },
    col: { span: 12 },
  },
];
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { axTable },
  data() {
    //这里存放数据
    return {
      api,
      radio: "radio", //单选
      dialogAdd: false,
      cuurentAreaCode: "",
      cuurentAreaCode1: [],
      gridId: "",
      options: [],
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 120,
      }),
      searchActions: [
        { name: "searchlist", text: "查询" },
        { name: "reset", text: "重置" },
      ],

      columns: this.$ax.tools.initColumn([
        {
          title: "活动名称",
          dataIndex: "eventName",
          ellipsis: true,
          width: 150,
        },
        {
          title: "活动类型",
          dataIndex: "eventType",
          ellipsis: true,
          width: 150,
        },
        {
          title: "举办地点",
          dataIndex: "location",
          ellipsis: true,
          width: 250,
        },
        {
          title: "所属网格",
          dataIndex: "gridName",
          ellipsis: true,
          width: 150,
        },
        {
          title: "活动状态",
          dataIndex: "eventState",
          ellipsis: true,
          width: 120,
        },
      ]),
      dataSourceParams: {},
      visible: false,
      // 多选
      rowSelection: {},
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    rowSelection(e) {
      console.log(e);
    },
  },
  //方法集合
  methods: {
    onSubmit() {
      this.$emit("close");
      this.$emit("selection-change", this.selectionRows);
    },
    //关闭弹窗
    close() {
      this.$emit("close");
    },
    // 表格多选触发回调
    onSelectChange(selectedRowKeys, selectionRows) {
      // console.log(selectionRows);
      this.selectionRows = selectionRows;
    },
    //字典
    async getPermissionType() {
      await api.dictData({ dicKind: "activity_Type" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.roleTable.$children[0].$refs.searchForm.setFormItemProp(
          "eventType",
          {
            options: { options },
          }
        );
      });
    },
    // 获取所属网格数据
    async owningGrid() {
      try {
        const res = await api.getTreeList(); // 调用接口获取数据
        if (res.success) {
          // 如果接口请求成功
          const options = this.processData(res.data); // 处理数据
          this.options = options; // 更新选项数据
        } else {
          console.error("获取所属网格数据失败:", res.message); // 输出错误信息
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
    },
    // 处理数据为级联选择器格式
    processData(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        children: item.children ? this.processData(item.children) : [], // 递归处理子节点
      }));
    },
    // 级联选择器改变的回调函数
    onChange(value, e) {
      // 获取最后一个ID
      const lastId = value[value.length - 1];
      // const address = e.map((item) => item.label).join("/");
      this.gridId = lastId;
      this.cuurentAreaCode = value[value.length - 1];
      this.cuurentAreaCode1 = value;
    },
    //重置
    reset(e) {
      this.cuurentAreaCode = "";
      this.cuurentAreaCode1 = [];
      if (this.$refs.cascader) {
        this.$refs.cascader.$refs.input.value = "";
      }
      this.gridId = ""; // 清空 gridId 参数
    },

    // 在搜索方法中根据情况传入或不传入 gridId 参数
    searchlist(e) {
      const formValues = { ...e.formValues };
      e.formValues.gridId = this.gridId || undefined;
      this.$refs.roleTable.getDataSource(e.formValues);
    },
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {
    this.owningGrid();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能,这个函数会触发
};
</script>
    <style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
</style>